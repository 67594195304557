import Link from "next/link";
import Image from "next/image";
import { Fragment, useState } from "react";

import { Dialog, Menu, Transition } from "@headlessui/react";
import { DotsCircleHorizontalIcon, SearchIcon } from "@heroicons/react/solid";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { removeUserToken } from "@/lib/features/auth/services";
import {
  useGetNotificationsQuery,
  useGetProfileQuery,
} from "@/lib/services/user";
import { useSelector } from "react-redux";
import { removeNotification } from "@/lib/features/notifications/notificationsSlice";
import { t, Trans } from "@lingui/macro";
import ChangeSupplier from "./ChangeSupplier";

const navigation = [
  {
    name: "Inboxes",
    href: "#",
    children: [
      { name: "Technical Support", href: "#" },
      { name: "Sales", href: "#" },
      { name: "General", href: "#" },
    ],
  },
  { name: "Reporting", href: "#", children: [] },
  { name: "Settings", href: "#", children: [] },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [changeSupplierIsOpen, setChangeSupplierIsOpen] = useState(false);
  const notifications = useSelector(
    (state: any) => state.notifications.notifications
  );
  const showNotifications = notifications.length > 0;
  const profileResult = useGetProfileQuery();
  const {
    data: allNotifications,
    isLoading: notificationsLoading,
    refetch,
  } = useGetNotificationsQuery();

  const user = profileResult?.data?.user;

  const { data: profile } = useGetProfileQuery();

  const signOut = () => {
    removeUserToken();
    window.location.href = "/";
  };

  const handleChangeSupplierPanel = () => {
    setChangeSupplierIsOpen(!changeSupplierIsOpen);
  };

  if (!user) return <></>;

  return (
    <header className="relative h-16 bg-gray-800 flex items-center">
      {/* Logo area */}
      <div className="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
        <Link href="/">
          <a
            href="#"
            className="flex items-center justify-start h-16 w-72
                focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:w-72 p-6"
          >
            <Image
              className="h-8 w-auto"
              src="/images/ros-logo-blanco.svg"
              height={35} // Desired size with correct aspect ratio
              width={100} // Desired size with correct aspect ratio
              objectFit="contain"
              alt="Rendalo logo"
            />
          </a>
        </Link>
      </div>

      {/* Menu button area */}
      <div className="absolute inset-y-0 right-0 pr-4 flex items-center sm:pr-6 md:hidden">
        {/* Mobile menu button */}
        <button
          type="button"
          className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-600"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/* Desktop nav area */}
      <div className="hidden md:min-w-0 md:flex-1 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1"></div>
        <div className="ml-10 pr-4 flex-shrink-0 flex items-center space-x-5">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex justify-center items-center w-full text-gray-400 rounded-md hover:text-white cursor-pointer px-4 py-2 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                <Trans>Create</Trans>
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                <div className="py-1">
                  {!profile?.supplier.is_rendalomaq && (
                    <Menu.Item>
                      {({ active }) => (
                        <Link href={"/leasing/create-simple"} passHref>
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm hover:bg-gray-100"
                            )}
                          >
                            <Trans>New lease</Trans>
                          </a>
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    {({ active }) => (
                      <Link href="/leads/add/requirement" passHref>
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm hover:bg-gray-100"
                          )}
                        >
                          <Trans>New requirement</Trans>
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="ml-auto flex-shrink-0 p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary rounded-md">
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-[350px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                {!notificationsLoading && allNotifications?.results.length ? (
                  <>
                    <h4 className="text-sm font-bold px-4 pt-2">
                      <strong>{t`Notifications`}</strong>
                    </h4>
                    <div className="py-1 divide-y">
                      {allNotifications?.results.map((notification) => (
                        <Menu.Item key={notification.id}>
                          {({ active }) => (
                            <Link
                              href={`${
                                notification.data.lead_id
                                  ? `/leads/${notification.data.lead_id}/detail`
                                  : ""
                              }`}
                              passHref
                            >
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm hover:bg-gray-100"
                                )}
                              >
                                <div className="flex items-start justify-between gap-2">
                                  <DotsCircleHorizontalIcon className="h-6 w-6 text-red-500" />
                                  {notification.body}
                                </div>
                              </a>
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col gap-2 items-center justify-center min-h-[150px] text-gray-400">
                    <BellIcon className="h-5 w-5" aria-hidden="true" />
                    <p className="text-gray-400 text-sm">
                      {t`You have no notifications at the moment`}
                    </p>
                  </div>
                )}
              </Menu.Items>
            </Transition>
          </Menu>

          <div className="flex items-center space-x-8">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="flex text-sm focus:outline-none focus:ring-0 ">
                <span className="sr-only">Open user menu</span>
                {user && (
                  <div className="bg-gray-700 rounded-md py-1 px-3 flex items-center gap-2">
                    <Image
                      className="h-4 w-4 rounded-full bg-white"
                      src={user.avatar_url} // Route of the image file
                      height={24} // Desired size with correct aspect ratio
                      width={24} // Desired size with correct aspect ratio
                      alt={user.first_name}
                    />

                    <div className="text-left">
                      <p className="text-white">
                        {user.first_name} {user.last_name}
                      </p>
                      <p className="text-xs text-gray-200">
                        {profile?.supplier?.name}
                      </p>
                    </div>
                  </div>
                )}
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {user?.is_staff || user?.is_superuser ? (
                      <Menu.Item>
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700"
                          onClick={handleChangeSupplierPanel}
                        >
                          <Trans>Change supplier</Trans>
                        </a>
                      </Menu.Item>
                    ) : (
                      <></>
                    )}
                    <Menu.Item>
                      <a
                        href="#"
                        onClick={signOut}
                        className={classNames(
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Cerrar sesión
                      </a>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <ChangeSupplier
        open={changeSupplierIsOpen}
        handlePanel={handleChangeSupplierPanel}
      />
      {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 md:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
            enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
            enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
            leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
          >
            <nav
              className="fixed z-40 inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg"
              aria-label="Global"
            >
              <div className="h-16 flex items-center justify-between px-4 sm:px-6">
                <a href="#">
                  <Image
                    className="block h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=orange&shade=500"
                    height={32} // Desired size with correct aspect ratio
                    width={32} // Desired size with correct aspect ratio
                    alt="Workflow"
                  />
                </a>
                <button
                  type="button"
                  className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-600"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-2 max-w-8xl mx-auto px-4 sm:px-6">
                <div className="relative text-gray-400 focus-within:text-gray-500">
                  <label htmlFor="mobile-search" className="sr-only">
                    Search all inboxes
                  </label>
                  <input
                    id="mobile-search"
                    type="search"
                    placeholder="Search all inboxes"
                    className="block w-full border-gray-300 rounded-md pl-10 placeholder-gray-500 focus:border-orange-600 focus:ring-orange-600"
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                {navigation.map((item) => (
                  <Fragment key={item.name}>
                    <a
                      href={item.href}
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                    >
                      {item.name}
                    </a>
                    {item.children.map((child) => (
                      <a
                        key={child.name}
                        href={child.href}
                        className="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100"
                      >
                        {child.name}
                      </a>
                    ))}
                  </Fragment>
                ))}
              </div>
              <div className="pt-4 pb-3">
                <div className="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
                  <div className="flex-shrink-0">
                    {user && (
                      <Image
                        className="h-10 w-10 rounded-full"
                        src={user.avatar_url} // Route of the image file
                        height={40} // Desired size with correct aspect ratio
                        width={40} // Desired size with correct aspect ratio
                        alt={user.first_name}
                      />
                    )}
                  </div>
                  <div className="ml-3 min-w-0 flex-1">
                    <div className="text-base font-medium text-gray-800 truncate"></div>
                    <div className="text-sm font-medium text-gray-500 truncate"></div>
                  </div>
                  <a
                    href="#"
                    className="ml-auto flex-shrink-0 bg-white p-2 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </a>
                </div>
                <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
                  <a
                    href="#"
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Cerrar sesión
                  </a>
                </div>
              </div>
            </nav>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </header>
  );
}
