import { Fragment, useEffect, useState } from "react";

import { CheckCircleIcon, HomeIcon } from "@heroicons/react/outline";

import { Transition } from "@headlessui/react";
import { XIcon, ArrowRightIcon } from "@heroicons/react/outline";
import NavBar from "./NavBar";
import { useGetProfileQuery } from "@/lib/services/user";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "@/lib/features/notifications/notificationsSlice";

import { Breadcrumb } from "flowbite-react";
import SideBar from "./SideBar";

import { loadIntercom } from "next-intercom";
import { useRouter } from "next/router";
import Link from "next/link";

import Head from "next/head";
import { Trans } from "@lingui/macro";
import Image from "next/image";
import moment from "moment";
import { dateFormat } from "@/lib/utils";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({
  children,
  title,
  current,
  hideSidebar,
  hideTitle,
}: {
  children: any;
  title?: string;
  current: string;
  hideSidebar?: boolean;
  hideTitle?: boolean;
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { pathname, query } = router;
  const notifications = useSelector(
    (state: any) => state.notifications.notifications
  );

  const showNotifications = notifications.length > 0;
  const profileResult = useGetProfileQuery();
  const user = profileResult?.data?.user;
  const supplier = profileResult?.data?.supplier;

  const [showSplash, setShowSplash] = useState<boolean>(false);

  useEffect(() => {
    if (supplier?.country_id !== "CL") {
      const country = supplier?.country_id;
      let locale;

      switch (country) {
        case "BR":
          locale = "pt-BR";
          break;
        case "MX":
          locale = "es-MX";
          break;
        default:
          locale = "";
          break;
      }

      router.push(
        {
          pathname,
          query,
        },
        router.asPath,
        { locale }
      );
      return;
    }
  }, [supplier]);

  useEffect(() => {
    const today = moment().format(dateFormat);
    const lastSplash = localStorage.getItem("lastSplash");
    if (lastSplash !== today) {
      setShowSplash(true);
      setTimeout(() => {
        setShowSplash(false);
        localStorage.setItem("lastSplash", `${moment().format(dateFormat)}`);
      }, 5000);
    }
  }, []);

  if (!user) return <></>;

  if (showSplash)
    return (
      <div
        className={classNames(
          showSplash ? "top-0" : "top-[-200vh]",
          "transition-all fixed  w-full h-[100vh] bg-gray-800 z-[9999999] flex justify-center items-center"
        )}
      >
        <Image
          className="h-8 w-auto"
          src="/images/ros-animation.gif"
          height={100} // Desired size with correct aspect ratio
          width={1000} // Desired size with correct aspect ratio
          objectFit="contain"
          alt="ROS logo"
        />
      </div>
    );

  if (user) {
    loadIntercom({
      appId: "g0hk57s1", // default : ''
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      ssr: false, // default: false
      initWindow: true, // default: true
      delay: 0, // default: 0  - usefull for mobile devices to prevent blocking the main thread
    });
  }

  const routes = router.pathname.split("/");
  const routeHandler = () => {
    let route = routes[1];
    router.push(`/${route}`);
  };

  return (
    <>
      <div className="h-full flex flex-col">
        <Head>
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"
            rel="stylesheet"
          />
        </Head>
        <NavBar />

        {/* Bottom section */}
        <div className="min-h-0 flex-1 flex overflow-hidden">
          {!hideSidebar && <SideBar />}
          <main
            className={classNames(
              !hideSidebar ? "rounded-tl-xl" : "",
              "min-w-0 flex-1 lg:flex bg-[#F1F6F9]"
            )}
          >
            {/* Primary column */}
            <section
              aria-labelledby="primary-heading"
              className="min-w-0 flex-1 h-full flex flex-col overflow-y-auto lg:order-last"
            >
              {!hideTitle && (
                <div className="min-w-0 p-4">
                  <Breadcrumb aria-label="Default breadcrumb example">
                    <Link href="/">
                      <div>
                        <Breadcrumb.Item href="/" icon={HomeIcon}>
                          <p className="text-gray-500 hover:text-primary">
                            Dashboard
                          </p>
                        </Breadcrumb.Item>
                      </div>
                    </Link>

                    <Breadcrumb.Item onClick={routeHandler}>
                      <p className="text-gray-500 hover:text-primary cursor-pointer capitalize">
                        {routes[1]}
                      </p>
                    </Breadcrumb.Item>

                    {query.id && (
                      <Breadcrumb.Item>
                        <p className="text-gray-500">
                          {routes[3] === "requirement" ? "Lead" : "Detail"}
                        </p>
                      </Breadcrumb.Item>
                    )}
                  </Breadcrumb>

                  {title && (
                    <h1
                      className="text-xl font-semibold mt-4"
                      id="section-title"
                    >
                      {title}
                    </h1>
                  )}
                </div>
              )}
              {children}
            </section>
          </main>
        </div>
      </div>

      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showNotifications}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end ">
              {notifications.map(
                (notification: Notification, index: number) => (
                  <div
                    key={index}
                    className="max-w-sm w-full bg-green-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                  >
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-6 w-6 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="text-base text-green-800">
                            <b>{notification.title}</b>
                          </p>
                          {notification.data ? (
                            <div>
                              <p className="mt-2 text-sm text-green-800">
                                <b>{`${notification.data.first_name} ${notification.data.last_name}`}</b>{" "}
                                <Trans>needs</Trans>:
                              </p>
                              <ul className="list-disc ml-4 mt-2">
                                {notification.data.products
                                  .split(",")
                                  .map((element: string, index: Number) => (
                                    <li key={`${element}_${index}`}>
                                      <p className="text-sm text-green-800">
                                        {element}
                                      </p>
                                    </li>
                                  ))}
                              </ul>
                              <p className="mt-2 text-sm text-green-800">
                                <Trans>Phone</Trans>:{" "}
                                <b>{notification.data.phone ?? "----"}</b>
                              </p>
                              <p className="mt-2 text-sm text-green-800">
                                <Trans>Email</Trans>:{" "}
                                <b>{notification.data.email ?? "----"}</b>
                              </p>
                              <p className="mt-2 text-sm text-green-800">
                                <Trans>Duration</Trans>:{" "}
                                <b>{notification.data.duration ?? "----"}</b>
                              </p>
                            </div>
                          ) : (
                            <p className="mt-2 text-sm text-green-800">
                              {notification.body}
                            </p>
                          )}
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                          <button
                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              dispatch(removeNotification(index));
                            }}
                          >
                            <span className="sr-only">Close</span>
                            <XIcon
                              className="h-6 w-6 text-green-800 bg-green-100"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          dispatch(removeNotification(index));
                          router.push(
                            `/leads/${notification.data.lead_id}/detail`
                          );
                        }}
                        className="mr-2 flex place-items-end flex-row-reverse cursor-pointer"
                      >
                        <ArrowRightIcon className="ml-2 h-6 w-6 text-green-800 bg-green-100" />
                        <b className="mt-2 text-sm text-green-800 col-span-1 underline">
                          <Trans>Go to the requirement</Trans>
                        </b>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
