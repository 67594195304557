import { GeoPoint, Supplier } from "./inventory";
import { ServiceArea } from "./leads";

export interface DetailUsers {
  id: number;
  supplier_id: number;
  user_id: number;
  user: User;
}

export interface UserData {
  is_email_enabled: boolean;
  is_whatsapp_enabled: boolean;
  dni?: string;
  id: number;
  supplier_id?: number;
  supplier?: Supplier;
  user: User;
  user_id: number;
  phone?: string;
  email?: string;
}
export interface User {
  permissions?: string[];
  is_superuser: boolean;
  supplier?: Supplier;
  id?: number;
  first_name: string;
  last_name: string;
  avatar_url: string;
  is_staff: boolean;
  email?: string;
  phone: string;
  searchName?: string;
  user?: User;
  service_area?: ServiceArea;
}

export interface SupplierBranch {
  point: GeoPoint;
}

export interface Profile {
  user: User;
  supplier_branch: SupplierBranch;
  supplier: Supplier;
  user_id: number;
}

export enum SupplierVersion {
  V1 = "v1",
  V2 = "v2",
}

export interface SupplierConfig {
  leads_terms_and_conditions: string;
  po_payment_info: string;
  suppliers_version: SupplierVersion;
}

export interface LoginResponse {
  token: string;
}

export interface LoginGoogleResponse {
  key: string;
}

export interface LoginPayload {
  username: string;
  password: string;
}

export interface AccessToken {
  access_token: string;
}
