import { Fragment, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Select from "react-select";
import { t, Trans } from "@lingui/macro";
import { useGetSuppliersQuery } from "@/lib/services/suppliers";
import { useChangeUserSupplierMutation } from "@/lib/services/user";
import { GoPage } from "@/lib/dto/requests";
import lodash from "lodash";
import { Supplier } from "@/lib/dto/inventory";

interface Props {
  open: boolean;
  handlePanel: () => void;
}

export default function ChangeSupplier({ open, handlePanel }: Props) {
  const [currentSupplier, setCurrentSupplier] = useState<number | undefined>();
  const [goPage, setGoPage] = useState<GoPage | null>({
    filters: {
      all_countries: true,
    },
  });

  const { data: suppliersData } = useGetSuppliersQuery(goPage);
  const [changeSupplier] = useChangeUserSupplierMutation();

  let suppliers: any[] = [];
  if (suppliersData?.results) {
    suppliers = [...suppliersData.results];
  }

  const onInputChange = (inputValue: string) => {
    setGoPage({
      ...goPage,
      cursor: "",
      isPrevious: false,
      search: inputValue,
    });
  };
  const debouncedSearch = useMemo(
    () => lodash.debounce(onInputChange, 500),
    []
  );

  const handleChangeUserSupplier = async () => {
    const res = await changeSupplier({
      supplier_id: currentSupplier,
    });

    handlePanel();
    window.location.replace("/");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[21474831001]"
        onClose={handlePanel}
      >
        <div className="fixed inset-0"></div>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-primary py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            <Trans>Change supplier</Trans>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-primary text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handlePanel}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="items"
                                className="block text-sm font-medium text-gray-700"
                              >
                                <Trans>Suppliers</Trans>
                              </label>
                              <Select
                                placeholder={t`Select...`}
                                options={suppliers}
                                onInputChange={(e) => debouncedSearch(e)}
                                getOptionLabel={(option: Supplier) =>
                                  option ? option.name : ""
                                }
                                getOptionValue={(option) =>
                                  option ? option.id.toString() : ""
                                }
                                onChange={(option) =>
                                  setCurrentSupplier(option?.id)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        onClick={handlePanel}
                      >
                        <Trans>Cancel</Trans>
                      </button>
                      <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        onClick={handleChangeUserSupplier}
                      >
                        <Trans>Change supplier</Trans>
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
