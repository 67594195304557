import Link from "next/link";
import {
  useGetProfileQuery,
  useGetSupplierConfigQuery,
} from "@/lib/services/user";
import { t } from "@lingui/macro";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import { useRouter } from "next/router";

import {
  openSidebar,
  closeSidebar,
  currentSidebarBtn,
  toggleSubMenu,
} from "@/lib/features/sidebar/sidebarSlice";
import { SupplierVersion } from "@/lib/dto/user";
interface Menu {
  name: string;
  href: string;
  icon: string;
  is_rendalo?: boolean;
  supplierVersion: string[];
  excludedCountries?: string[];
  title: string;
  subMenu?: Menu[];
  parent?: string;
}

export default function SideBar() {
  let allSidebarNavigation: Menu[] = [
    {
      name: "dashboard",
      href: "/",
      icon: "dashboard",
      is_rendalo: false,
      title: t`Dashboard`,
      supplierVersion: ["V1"],
    },
    {
      name: "dashboards",
      href: "/",
      icon: "dashboard",
      is_rendalo: false,
      title: t`Dashboard`,
      supplierVersion: ["V2"],
      subMenu: [
        {
          name: "my_space",
          href: "/my-space",
          icon: "account_circle",
          is_rendalo: true,
          title: t`My Space`,
          parent: "dashboards",
          supplierVersion: ["V2"],
        },
        {
          name: "management",
          href: "/management",
          icon: "insert_chart",
          is_rendalo: true,
          title: t`General`,
          parent: "dashboards",
          supplierVersion: ["V2"],
        },
        {
          name: "latam",
          href: "/latam-dashboard",
          icon: "data_exploration",
          is_rendalo: true,
          title: t`Latam`,
          parent: "dashboards",
          supplierVersion: ["V2"],
        },
      ],
    },
    {
      name: "requirements",
      href: "",
      icon: "move_to_inbox",
      is_rendalo: true,
      title: t`Requirements`,
      supplierVersion: ["V1", "V2"],
      subMenu: [
        {
          name: "requirments_list",
          href: "/leads",
          icon: "format_list_bulleted",
          is_rendalo: true,
          title: t`List`,
          parent: "requirements",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "tasks_list",
          href: "/tasks",
          icon: "assignment_turned_in",
          is_rendalo: true,
          title: t`Tasks`,
          parent: "requirements",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "schedule",
          href: "/schedule",
          icon: "calendar_month",
          is_rendalo: true,
          title: t`Plan`,
          parent: "schedule",
          supplierVersion: ["V1", "V2"],
        },
      ],
    },
    {
      name: "sales",
      href: "",
      icon: "attach_money",
      is_rendalo: true,
      title: t`Sales`,
      supplierVersion: ["V1", "V2"],
      subMenu: [
        {
          name: "bugdets_list",
          href: "/documents/budgets",
          icon: "post_add",
          is_rendalo: true,
          title: t`Budgets`,
          parent: "sales",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "invoices_list",
          href: "/documents/sales-invoices",
          icon: "receipt_long",
          is_rendalo: true,
          title: t`Invoices`,
          parent: "sales",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "commercial_control_sales",
          href: "/commercial-control/sales",
          icon: "query_stats",
          is_rendalo: true,
          title: t`Commercial control`,
          parent: "sales",
          supplierVersion: ["V2"],
        },
        {
          name: "sales_payments",
          href: "/payments/sales-payments",
          icon: "money",
          is_rendalo: true,
          title: t`Received Payments`,
          parent: "sales",
          supplierVersion: ["V2"],
        },
      ],
    },
    {
      name: "bills",
      href: "",
      icon: "payments",
      is_rendalo: true,
      title: t`Bills`,
      supplierVersion: ["V1", "V2"],
      subMenu: [
        {
          name: "purchase_orders_list",
          href: "/documents/buy-orders",
          icon: "request_quote",
          is_rendalo: true,
          title: t`Purchase orders`,
          parent: "bills",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "purchase_order_list",
          href: "/documents/purchase-order-invoices",
          icon: "receipt_long",
          is_rendalo: true,
          title: t`Invoices`,
          parent: "bills",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "commercial_control_purchases",
          href: "/commercial-control/purchases",
          icon: "query_stats",
          is_rendalo: true,
          title: t`Commercial control`,
          parent: "bills",
          supplierVersion: ["V2"],
        },

        {
          name: "buy_payments",
          href: "/payments/buy-payments",
          icon: "money",
          is_rendalo: true,
          title: t`Payments`,
          parent: "sales",
          supplierVersion: ["V2"],
        },
      ],
    },
    {
      name: "inventory",
      href: "",
      icon: "inventory",
      is_rendalo: false,
      title: t`Inventory`,
      supplierVersion: ["V1", "V2"],
      subMenu: [
        {
          name: "inventory_list",
          href: "/inventory",
          icon: "format_list_bulleted",
          is_rendalo: false,
          title: t`List`,
          parent: "inventory",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "operability",
          href: "/operability",
          icon: "timeline",
          is_rendalo: true,
          title: t`Operability`,
          parent: "inventory",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "geolocation",
          href: "/geolocation",
          icon: "location_on",
          is_rendalo: false,
          title: t`Location`,
          parent: "inventory",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "usage-report",
          href: "/usage-report",
          icon: "timeline",
          is_rendalo: true,
          title: t`Usage report`,
          parent: "inventory",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "categories",
          href: "/categories",
          icon: "bookmarks",
          is_rendalo: true,
          title: t`Categories`,
          parent: "inventory",
          supplierVersion: ["V1", "V2"],
        },
      ],
    },
    {
      name: "leasing",
      href: "",
      icon: "local_shipping",
      is_rendalo: true,
      excludedCountries: ["es-MX"],
      title: t`Leasing`,
      supplierVersion: ["V1"],
      subMenu: [
        {
          name: "leasing_list",
          href: "/leasing",
          icon: "format_list_bulleted",
          is_rendalo: false,
          title: t`List`,
          parent: "leasing",
          supplierVersion: ["V1"],
        },
      ],
    },
    {
      name: "rates",
      href: "",
      icon: "currency_exchange",
      is_rendalo: true,
      title: t`Rates`,
      supplierVersion: ["V1", "V2"],
      subMenu: [
        {
          name: "budget_conditions_list",
          href: "/budget-conditions",
          icon: "local_atm",
          is_rendalo: true,
          title: t`Rates`,
          parent: "rates",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "suppliers_fee_list",
          href: "/suppliers-fee",
          icon: "money",
          is_rendalo: true,
          title: t`Suppliers fee`,
          parent: "rates",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "geo_lease_prices",
          href: "/geo-lease-price",
          icon: "share_location",
          is_rendalo: true,
          title: t`Geo fees`,
          parent: "rates",
          supplierVersion: ["V1", "V2"],
        },
      ],
    },
    {
      name: "clients",
      href: "",
      icon: "contact_mail",
      title: t`Clients`,
      is_rendalo: true,
      supplierVersion: ["V1", "V2"],
      subMenu: [
        {
          name: "organizations",
          href: "/organizations",
          icon: "corporate_fare",
          title: t`Organizations`,
          parent: "clients",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "customers",
          href: "/customers",
          icon: "account_circle",
          title: t`Customers`,
          parent: "clients",
          supplierVersion: ["V1", "V2"],
        },
      ],
    },
    {
      name: "suppliers",
      href: "",
      icon: "precision_manufacturing",
      title: t`Suppliers`,
      is_rendalo: true,
      supplierVersion: ["V1", "V2"],
      subMenu: [
        {
          name: "suppliers",
          href: "/suppliers",
          icon: "home_work",
          is_rendalo: true,
          title: t`Suppliers`,
          parent: "suppliers",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "branches",
          href: "/branches",
          icon: "warehouse",
          is_rendalo: true,
          title: t`Branches`,
          parent: "suppliers",
          supplierVersion: ["V1", "V2"],
        },
        {
          name: "supplier-users",
          href: "/supplier-users",
          icon: "engineering",
          is_rendalo: true,
          title: t`Supplier users`,
          parent: "suppliers",
          supplierVersion: ["V1", "V2"],
        },
      ],
    },
  ];

  const profileResult = useGetProfileQuery();
  const is_rendalomaq = profileResult?.data?.supplier.is_rendalomaq;
  const supplierConfig = useGetSupplierConfigQuery();
  const isSupplierV2 =
    supplierConfig.data?.suppliers_version === SupplierVersion.V2;
  const router = useRouter();
  const { pathname } = router;
  const locale = router.locale;

  if (!is_rendalomaq) {
    allSidebarNavigation = allSidebarNavigation.filter(
      (item) => item.name != "inventory"
    );
    allSidebarNavigation.push(
      {
        name: "inventory_list",
        href: "/inventory",
        icon: "format_list_bulleted",
        is_rendalo: false,
        title: t`List`,
        supplierVersion: ["V1", "V2"],
      },
      {
        name: "geolocation",
        href: "/geolocation",
        icon: "location_on",
        is_rendalo: false,
        title: t`Location`,
        supplierVersion: ["V1", "V2"],
      }
    );
  }

  const sidebarNavigation = allSidebarNavigation
    .filter(
      (menu) =>
        (is_rendalomaq || !menu.is_rendalo) &&
        locale &&
        !menu.excludedCountries?.includes(locale) &&
        (isSupplierV2
          ? menu.supplierVersion.includes("V2")
          : menu.supplierVersion.includes("V1"))
    )
    .map((item) => {
      if (item.subMenu) {
        item.subMenu = item.subMenu.filter(
          (subItem) =>
            (is_rendalomaq || !subItem.is_rendalo) &&
            locale &&
            !subItem.excludedCountries?.includes(locale) &&
            (isSupplierV2
              ? subItem.supplierVersion.includes("V2")
              : subItem.supplierVersion.includes("V1"))
        );
      }
      return item;
    });

  const dispatch = useDispatch();

  // SUB MENU WHEN IS OPEN
  const { isOpen, expandedSubMenuName, subMenuOpen } = useSelector(
    (state: RootState) => state.sidebar
  );

  // SUB MENU WHEN THE MENU IS CLOSE
  const [openHover, setOpenHover] = useState(Boolean);
  const [elementHover, setElementHover] = useState();

  const handleSubmenu = (index: string, href: string) => {
    if (index === expandedSubMenuName) {
      dispatch(toggleSubMenu(!subMenuOpen));
    } else if (href) {
      router.push(href);
    } else {
      dispatch(currentSidebarBtn(index));
      dispatch(toggleSubMenu(true));
    }
  };

  const handleHover = (index: any, item: any) => {
    setElementHover(index);
    setOpenHover(true);
  };

  const handleHoverOut = (e: any) => {
    let path = e.path || (e.composedPath && e.composedPath());
    if (path[0].localName !== "span") {
      setOpenHover(false);
    }
  };

  if (!isOpen) {
    document.body.addEventListener("click", (e) => handleHoverOut(e));
  }

  const handleActive = (item: any) => {
    if (isOpen) {
      dispatch(currentSidebarBtn(item));
    }
  };

  // I evaluate which menu should be displayed every time the pathname changes
  useEffect(() => {
    const targetSubMenuElement = allSidebarNavigation
      .map(
        (item) =>
          item.subMenu &&
          item.subMenu.find((subNav) => subNav.href === pathname)
      )
      .find((subNav) => subNav);
    if (targetSubMenuElement) {
      let itemSelecter = {
        name: targetSubMenuElement.parent,
      };

      dispatch(currentSidebarBtn(itemSelecter));
    }
  }, [pathname]);

  return (
    <aside
      aria-label="Sidebar"
      className={`md:block md:flex-shrink-0 md:bg-gray-800 transition-all ease-in-out duration-400 overflow-y-auto ${
        isOpen ? "w-64" : "w-[63px]"
      }`}
    >
      <div className="pt-2 mx-2 space-y-2 pb-20">
        {sidebarNavigation.map((item, index) => (
          <div key={item.name}>
            <span
              id={item.name}
              onMouseEnter={() => !isOpen && handleHover(index, item)}
              onClick={() => {
                handleSubmenu(item.name, item.href);
                handleHover(index, item);
                handleActive(item);
              }}
              className={`group flex items-center gap-x-2 justify-between text-gray-400 hover:text-white cursor-pointer px-3 py-3 hover:bg-gray-900 rounded-lg transition-all ease duration-400 ${
                item.name == expandedSubMenuName || item.href == pathname
                  ? "bg-gray-700 text-white"
                  : ""
              }`}
            >
              <span className="flex justify-start">
                <span className="sr-only">{item.name}</span>
                <span
                  className={`material-icons-outlined md-18 group-hover:text-safety-orange ${
                    item.name === expandedSubMenuName &&
                    isOpen &&
                    subMenuOpen &&
                    "text-safety-orange"
                  }`}
                >
                  {item.icon}
                </span>
                <span
                  className={`ml-2 ${
                    !isOpen ? "hidden opacity-0" : "block opacity-100"
                  } transition-all ease-in-out duration-400`}
                >
                  {item.title}
                </span>
              </span>
              {item.subMenu && isOpen && (
                <span
                  className={`material-icons-outlined md-18 group-hover:text-safety-orange transition-all ease-in-out duration-400 ${
                    item.name === expandedSubMenuName &&
                    isOpen &&
                    subMenuOpen &&
                    "rotate-180 text-safety-orange"
                  }`}
                >
                  keyboard_arrow_down
                </span>
              )}
            </span>
            {/* OPEN MENU */}
            <div className="overflow-hidden">
              <ul
                className={`my-2 opacity-0 rounded-md transition-all ease-in-out duration-400 ${
                  item.subMenu &&
                  item.name == expandedSubMenuName &&
                  subMenuOpen &&
                  isOpen
                    ? "opacity-100 bg-gray-700/50"
                    : "opacity-0 hidden"
                } `}
              >
                {item?.subMenu?.map((subMenu, index) => (
                  <Link
                    href={subMenu.href ? subMenu.href : ""}
                    key={subMenu.name}
                  >
                    <li
                      className={`group flex items-center gap-x-2 border-b border-gray-700 transition-all ease-in-out duration-400 ${
                        subMenu.href === pathname && "bg-gray-900"
                      } text-gray-200 hover:text-white hover:bg-gray-900 cursor-pointer px-3 py-3 rounded-md`}
                    >
                      <span className="sr-only">{subMenu.name}</span>
                      <span
                        className={`${
                          subMenu.href === pathname && "text-safety-orange"
                        } material-icons-outlined md-18 group-hover:text-safety-orange`}
                      >
                        {subMenu.icon}
                      </span>
                      <span className={`ml-2 ${!isOpen && "hidden"}`}>
                        {subMenu.title}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>

            {/* CLOSE MENU */}
            {item.subMenu && !isOpen && openHover && elementHover === index && (
              <div className="absolute z-20">
                <div className="absolute w-6 h-6 -top-8 left-[65px] bg-dark-jungle rotate-45"></div>
                <div className="absolute w-52 h-20 -top-12 left-16">
                  <ul className="my-2 bg-dark-jungle rounded-md shadow-md">
                    <div className="text-white font-semibold pl-4 text-md pt-3 pb-3 rounded-t-lg">
                      {item.title}
                    </div>
                    {item.subMenu.map((subMenu, index) => (
                      <Link
                        href={subMenu.href ? subMenu.href : ""}
                        key={subMenu.name}
                      >
                        <li
                          className={`group flex items-center gap-x-2 border-t border-gray-700 text-gray-400 hover:text-white cursor-pointer px-3 py-3 hover:bg-gray-900 rounded-lg ${
                            subMenu.href === pathname &&
                            "bg-gray-900 text-gray-200"
                          }`}
                        >
                          <span className="sr-only">{subMenu.name}</span>
                          <span
                            className={`${
                              subMenu.href === pathname && "text-safety-orange"
                            } material-icons-outlined md-18 group-hover:text-safety-orange`}
                          >
                            {subMenu.icon}
                          </span>
                          <span className={`ml-2`}>{subMenu.title}</span>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="">
        <div
          className={`bg-gradient-to-t from-dark-jungle via-dark-jungle to-transparent absolute z-10 h-20 bottom-0 transition-all ease-in-out duration-400 ${
            isOpen ? "w-64" : "w-[63px]"
          }`}
        ></div>
        <button
          onClick={() => {
            isOpen ? dispatch(closeSidebar("")) : dispatch(openSidebar(""));
          }}
          className={`absolute bottom-0 transition-all ease-in-out duration-400 z-20 ${
            !isOpen ? "w-[48px]" : "w-[240px]"
          } bottom-4 mx-2 text-gray-400 flex justify-center hover:text-safety-orange cursor-pointer px-4 py-2 bg-gray-700 hover:bg-gray-900 rounded-lg`}
        >
          <span
            className={`material-icons-outlined text-center transition-all ease duration-400 ${
              !isOpen && "rotate-180"
            }`}
          >
            arrow_back
          </span>
        </button>
      </div>
    </aside>
  );
}
